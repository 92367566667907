/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import Image from '../components/image'
import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade'

import FotoCarousel from '../components/fotocarousel'
import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'
import { globalCss, variables, colors } from '../styles/global'
import ScrollingTitle from '../components/scrollingtitle'
import Testimonials from '../components/testimonials'
import BorderButton from '../components/borderbutton'
import { IoArrowDownCircleOutline } from 'react-icons/io5'

import videoposter from '../images/videoposter.jpg'

import '../styles/reset.css'
import bandfotoSM from '../images/bandfotoSM.jpg'
import bandfotoL from '../images/bandfotoL.jpg'
import bandfotoXL from '../images/bandfotoXL.jpg'

import SpacerPicture from '../components/SpacerPicture'

const IndexPage = ({ data }: any) => {
  return (
    <Layout hideMenu showButton>
      <SEO
        title="Op één been de beste coverband!"
        description="Dé Party-Animals voor een eersteklas feest!"
      />
      <div
        className="container"
        css={css`
          width: 100vw;
          max-width: 100%;
          height: 100vh;
          background-color: black;
          display: block;
          position: relative;
          ${variables.mobile} {
            height: 30vh;
          }
        `}
      >
        <picture>
          <source
            media="(max-width: 600px)"
            srcSet={bandfotoSM}
            type="image/jpeg"
          />

          <source
            media="(max-width: 1600px) and (min-width: 601px)"
            srcSet={bandfotoL}
            type="image/jpeg"
          />

          <source
            media="(min-width: 1601px)"
            srcSet={bandfotoXL}
            type="image/jpeg"
          />

          <img
            src={bandfotoSM}
            alt="bandfoto"
            css={css`
              width: 100%;
              height: 100%;
              object-fit: cover;
            `}
          />
        </picture>

        <div
          color={colors.white}
          css={css`
            position: absolute;
            left: 50%;
            bottom: 10px;
            height: 40px;
            transform: translate(-50%, -50%);
            opacity: 0.6;
            transition: opacity 0.2s;
            color: ${colors.white};
            &:hover {
              opacity: 1;
              cursor: pointer;
            }
            ${variables.mobile} {
              display: none;
            }
          `}
          onClick={() => {
            window.scrollTo({
              top: window.innerHeight,
              behavior: 'smooth',
            })
          }}
        >
          SCROLL
        </div>
        <div
          css={css`
            position: absolute;
            left: 50%;
            bottom: 0px;
            height: 35px;
            width: 2px;
            transform: translate(-50%, 0%);
            opacity: 0.6;
            transition: opacity 0.2s;
            background-color: ${colors.white};
            &:hover {
              opacity: 1;
              cursor: pointer;
            }
            ${variables.mobile} {
              display: none;
            }
          `}
        ></div>
      </div>
      <div
        className="container"
        css={css`
          background-color: ${colors.black};
          color: ${colors.white};
          max-width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 70px ${variables.HPaddingDesktop}px 70px
            ${variables.HPaddingDesktop}px;
          flex-wrap: wrap;
          ${variables.mobile} {
            padding: 60px ${variables.HPaddingMobile}px 0px
              ${variables.HPaddingMobile}px;
          }

          ${variables.mediumScreen} {
            max-width: 800px;
            margin: auto;
          }
        `}
      >
        <div
          css={css`
            width: 45%;
            ${variables.mediumScreen} {
              width: 100%;
            }
          `}
        >
          <Fade duration={1500} top>
            <h2
              css={css`
                color: ${colors.black};
                width: 100%;
                flex-shrink: 0;
                white-space: nowrap;
                ${variables.mobile} {
                  white-space: normal;
                }
              `}
            >
              Eersteklas Party-Animals
            </h2>
          </Fade>
          <Slide left>
            <p css={css``}>
              Festivaltent, bruiloft of bedrijfsfeest, deze eersteklas
              party-animals weten precies hoe ze elke party naar hogere sferen
              kunnen tillen! Van danceknallers tot stevige rock, van 90's hits
              tot Nederlandstalige meezingers, deze funky vogels draaien hun
              pootjes er niet voor om. Met hun enorme podiumskills staat dit
              zestal garant voor een episch feest!
            </p>
            <div
              css={css`
                margin-top: 40px;
                margin-bottom: 60px;
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: start;
                ${variables.mediumScreen} {
                }
              `}
            >
              <Link to="/contact" style={{ textDecoration: 'none' }}>
                <BorderButton text="Contact" />
              </Link>
              <div
                css={css`
                  width: 50px;
                  height: 20px;
                  ${variables.mobile} {
                    width: 10px;
                  }
                `}
              ></div>
              <Link to="/repertoire" style={{ textDecoration: 'none' }}>
                <BorderButton text="Repertoire" />
              </Link>
            </div>
            {/* <h4>Hoogvliegers</h4>
            <p>
              De First Class Flamingo's hebben kwaliteit hoog in het vaandel
              staan. Deze 6 beroepsmuzikanten hebben ieder een breed cv aan
              podiumervaring, en weten precies hoe ze op jouw feest het dak eraf
              moeten spelen. Bruiloft of festival, bedrijfsfeest of borrel, deze
              energieke band krijgt gegarandeerd de zaal aan het dansen
            </p>
          </Slide>
          <Slide left wait={400}>
            <h4>Jóuw feest</h4>
            <p>
              Iedereen houdt van goede muziek, maar smaken verschillen en de
              First Class Flamingos spelen hier met liefde op in. Van funky hits
              tot zomerse dancetracks, van zeroes heroes tot Nederlandstalig,
              van Bruno Mars tot Beyoncé, you name it! De band speelt tientallen
              songs in medley-vorm, zo is er voor ieder wat wils.
            </p> */}
          </Slide>
        </div>
        <div
          css={css`
            width: 45%;

            ${variables.mediumScreen} {
              width: 100%;
              margin-top: 1rem;
              margin-bottom: 5rem;
            }
          `}
        >
          <div className="youtube-video-container">
            <iframe
              id="promo"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/o43CK-V1W4w?si=GceG5VNjlxLpud7Z&amp;controls=1&amp;autoplay=1&amp;modestbranding=1&amp;mute=1&amp;loop=1&amp;showinfo=0&amp;rel=0"
              title="First Class Flamingos PROMO"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
            ></iframe>
          </div>
        </div>
      </div>
      <div
        css={css`
          width: full;
          ${variables.mobile} {
            display: none;
          }
        `}
      >
        <SpacerPicture
          sources={['/fotos/bannernieuw1.webp', '/fotos/bannernieuw1.jpg']}
        />
      </div>
      <div
        className="container"
        css={css`
          background-color: ${colors.black};
          color: ${colors.white};
          max-width: 100%;
          display: flex;
          align-items: start;
          justify-content: center;
          padding: 150px ${variables.HPaddingDesktop}px 150px
            ${variables.HPaddingDesktop}px;
          ${variables.mobile} {
            padding: 20px ${variables.HPaddingMobile}px 10px
              ${variables.HPaddingMobile}px;
          }
        `}
      >
        <div
          css={css`
            width: 45%;

            margin-right: 2rem;
            ${variables.mediumScreen} {
              display: none;
            }
          `}
        >
          <FotoCarousel />
        </div>

        <div
          css={css`
            width: 45%;
            margin-top: -3rem;
            ${variables.mediumScreen} {
              max-width: 800px;

              margin: auto;
              width: 100%;
              margin-top: 0;
            }
          `}
        >
          <Fade duration={1500} top>
            <h2
              css={css`
                flex-shrink: 0;
                margin-bottom: -40px;
                ${variables.mobile} {
                  margin-bottom: -20px;
                }
              `}
            >
              Meet & Greet?
            </h2>
          </Fade>
          <Slide right>
            <h4>Kom een keer kijken!</h4>
            <p
              css={css`
                margin-bottom: 30px;
              `}
            >
              Een band boeken doe je niet zomaar. Daarom nodigen we je graag uit
              voor een meet & greet met de band. Kom naar een van de exclusieve
              kijksessies, waar je vrijblijvend met de band kunt kennismaken en
              getrakteerd wordt op een gratis miniconcertje!
            </p>
          </Slide>
          <Link to="/kijksessie" style={{ textDecoration: 'none' }}>
            <BorderButton text="Meld je nu aan!" />
          </Link>
          <div
            css={css`
              width: full;
              display: none;
              ${variables.mediumScreen} {
                display: block;
                margin-top: 50px;
              }
            `}
          >
            <FotoCarousel />
          </div>
        </div>
      </div>
      <div
        css={css`
          width: full;
          ${variables.mobile} {
            display: none;
          }
        `}
      >
        <SpacerPicture
          sources={['/fotos/bannernieuw3.webp', '/fotos/bannernieuw3.jpg']}
        />
      </div>
      <Testimonials testimonials={data.recensieData.edges} />
    </Layout>
  )
}

export const query = graphql`
  query homeQuery {
    recensieData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "recensie" } } }
    ) {
      edges {
        node {
          frontmatter {
            recensie
            datum
            namen
            stars
            pos
          }
        }
      }
    }
  }
`

export default IndexPage
